import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import WebUI1080Articles from 'components/Web_User_Interface/1080p_Series/IntroWebUI1080';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR Full HD Web User Interface' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='The HTML5 web user interface allows an easy configuration and camera control through your default web browser like Chrome, Safari, Firefox, Internet Explorer.' image='/images/Search/QI_SearchThumb_WebUI_1080p.png' twitter='/images/Search/QI_SearchThumb_WebUI_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/' locationFR='/fr/Web_User_Interface/1080p_Series/' crumbLabel="1080p WebUI" mdxType="BreadCrumbs" />
    <WebUI1080Articles mdxType="WebUI1080Articles" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      